body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

@media (max-width: 960px) {
  .MuiGrid-root {
    flex-direction: column;
  }
}